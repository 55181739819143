import { memo, useState } from "react";
import { Link } from "react-router-dom";
import {
	footerData,
	footerLine,
	IconContactMail,
	IconContactMap,
	IconContactTelaphone,
	IconContactWhatsapp,
	IconFollowusFb,
	IconFollowusIg,
	lineYB,
	slogan,
} from "config/defaultData";
import { ContactUs } from "config/text";
import "../../assets/fontawesome-free/css/all.css";
import "./index.css";

function Footer() {
	const [onMobile, setOnMobile] = useState(window.innerWidth <= 991.98);

	return (
		<div className="footer">
			<div className="container" id="footer_new1">
				<div
					className="logo-slogan"
					style={{ backgroundImage: `url(${slogan})` }}
				></div>
				<div
					className="logo-footer-line"
					style={{ backgroundImage: `url(${onMobile ? lineYB : footerLine})` }}
				></div>
				<div className="logo-group">
					{footerData.map((i) => (
						<div key={i.id} className="logo">
							<img src={i.src} />
						</div>
					))}
				</div>
				<div className="row"></div>
			</div>

			<div className="container-fluid" id="footer_new2">
				{/* <div className="d-none d-xl-block"> */}
				<div className="d-none d-lg-block">
					<div className="row col-12 col-sm-10 offset-sm-1 py-4 mx-0 mx-sm-auto">
						<div
							className="section col-3 for-mobile hover"
							style={{ flexGrow: "4" }}
						>
							<div className="title">{ContactUs}</div>
							<div className="content">
								<ul>
									<li>
										<img src={IconContactMap} />
										<a href="mailto:cs@mrwrapper.com.hk">
											九龍灣臨利街1號富洋工業中心地下5號舖
										</a>
									</li>

									<li>
										<img src={IconContactTelaphone} />
										<a href="tel:+85294455015">(852)9445 5015</a>
									</li>

									<li>
										<img src={IconContactWhatsapp} />
										<a
											href="https://wa.me/85237087275"
											target="_blank"
											rel="noreferrer"
										>
										3708 7275
										</a>
									</li>
								</ul>
							</div>
						</div>

						<div
							className="section col-3 for-mobile text-center"
							style={{ flexGrow: "3" }}
						>
							<div className="title">產品及服務</div>
							<div className="content">
								<ul>
									<li>
										<Link className="nav-link mx-auto" to="/ppf">
											PPF 保護膜
										</Link>
									</li>
									{/* <li><Link className="nav-link" to="#">會籍計劃</Link></li> */}
									<li>
										<Link className="nav-link mx-auto" to="/gyeon">
											GYEON 鍍膜
										</Link>
									</li>
									{/* <li><Link className="nav-link mx-auto" to="/window-film">車窗隔熱膜</Link></li> */}
									{/* <li><Link className="nav-link" to="#" data-toggle="modal" data-target="#gyeon-tc-modal">鍍膜條款</Link></li> */}
									{/* <li><Link className="nav-link" to="#" data-toggle="modal" data-target="#ppf-tc-modal">PPF條款</Link></li> */}
								</ul>
							</div>
						</div>

						<div
							className="section col-3 for-mobile text-center"
							style={{ flexGrow: "3" }}
						>
							<div className="title">
								{/* 關連業務 */}
								探索更多
							</div>
							<div className="content">
								<ul>
									{/* <li><a className="nav-link" href="http://wasphk-rental.com">黃蜂租車</a></li> */}
									{/* <li><a className="nav-link" href="http://parkingbees.com.hk">AI Parking Bees</a></li> */}
									<li>
										<a
											className="nav-link disable-for-button mx-auto"
											href="./"
										>
											媒體報導
										</a>
									</li>
									<li>
										<a
											className="nav-link disable-for-button mx-auto"
											href="./"
										>
											工作機會
										</a>
									</li>
								</ul>
							</div>
						</div>

						<div
							className="section col-2 for-mobile text-end"
							style={{ flexGrow: "2" }}
						>
							<div className="title">追蹤我們</div>
							<div className="content">
								<div className="follow">
									<div className="content">
										{/* <!--
                                    <div className="icon"><a href="https://www.facebook.com/mrwrapper.com.hk"><img src="/uploads/images/icons/icon-fb.svg"></a></div>
                                    <div className="icon"><a href="https://www.instagram.com/mrwrapper.hk"><img src="/uploads/images/icons/icon-ig.svg"></a></div>
                                    !--> */}
										<div className="icon align-self-end">
											<a href="https://www.facebook.com/mrwrapper.com.hk">
												<img src={IconFollowusFb} />
											</a>
										</div>
										<div className="icon align-self-end">
											<a href="https://www.instagram.com/mrwrapper.hk">
												<img src={IconFollowusIg} />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <div className="d-block d-xl-none accordion" id="footer_accordion">
                <div className="card">
                    <div className="card-header" id="footer_heading_2">
                        <div className="button collapsed" type="button" data-toggle="collapse" data-target="#footer_collapse_2" aria-expanded="false" aria-controls="footer_collapse_2">
                            <div className="label">聯絡我們</div>
                            <div className="arrow">
                                <div className="arrow1">
                                    <i className="fas fa-angle-up"></i>
                                </div>
                                <div className="arrow2">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="footer_collapse_2" className="collapse" aria-labelledby="footer_heading_2" data-parent="#footer_accordion" style={{}}>
                        <div className="card-body">
                            <div className="content">
                                <ul>
                                    <li>
                                        <img src="/web/public/assets/images/contact-map.svg"/>
                                        <a  href="mailto:cs@mrwrapper.com.hk">九龍灣臨利街1號富洋工業中心地下5號舖</a>
                                    </li>

                                    <li>
                                        <img src="/web/public/assets/images/contact-telaphone.svg"/>
                                        <a href="tel:+85237087275">(852)9445 5015</a>
                                    </li>

                                    <li>
                                        <img src="/web/public/assets/images/contact-whatsapp.svg"/>
                                        <a href="https://wa.me/85237087275" target="_blank">9445 5015</a>
                                    </li>

                                    <li>
                                        <img src="/web/public/assets/images/contact-mail.svg"/>
                                        <a href="mailto:cs@mrwrapper.com.hk">cs@mrwrapper.com.hk</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="footer_heading_1">
                        <div className="button collapsed" type="button" data-toggle="collapse" data-target="#footer_collapse_1" aria-expanded="true" aria-controls="footer_collapse_1">
                            <div className="label">產品及服務</div>
                            <div className="arrow">
                                <div className="arrow1">
                                    <i className="fas fa-angle-up"></i>
                                </div>
                                <div className="arrow2">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="footer_collapse_1" className="collapse" aria-labelledby="footer_heading_1" data-parent="#footer_accordion">
                        <div className="card-body">
                            <div className="content">
                                <ul>
                                    <li><a className="nav-link" href="/web/ppf">PPF 保護膜</a></li>
                                    <li><a className="nav-link" href="/web/gyeon">GYEON 鍍膜</a></li>
                                    <li><a className="nav-link" href="#" data-toggle="modal" data-target="#gyeon-tc-modal">鍍膜條款</a></li>
                                    <li><a className="nav-link" href="#" data-toggle="modal" data-target="#ppf-tc-modal">PPF條款</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="footer_heading_5">
                        <div className="button collapsed" type="button" data-toggle="collapse" data-target="#footer_collapse_5" aria-expanded="false" aria-controls="footer_collapse_5">
                            <div className="label">關連業務</div>
                            <div className="arrow">
                                <div className="arrow1">
                                    <i className="fas fa-angle-up"></i>
                                </div>
                                <div className="arrow2">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="footer_collapse_5" className="collapse" aria-labelledby="footer_heading_5" data-parent="#footer_accordion">
                        <div className="card-body">
                            <div className="content">
                                <ul>
                                    <li><a className="nav-link" href="http://wasphk-rental.com">黃蜂租車</a></li>
                                    <li><a className="nav-link" href="http://parkingbees.com.hk">AI Parking Bees</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                 <!--
                <div className="card">
                    <div className="card-header" id="footer_heading_3">
                        <div className="button collapsed" type="button" data-toggle="collapse" data-target="#footer_collapse_3" aria-expanded="false" aria-controls="footer_collapse_3">
                            <div className="label">追蹤我們</div>
                            <div className="arrow">
                                <div className="arrow1">
                                    <i className="fas fa-angle-up"></i>
                                </div>
                                <div className="arrow2">
                                    <i className="fas fa-angle-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="footer_collapse_3" className="collapse" aria-labelledby="footer_heading_3" data-parent="#footer_accordion">
                        <div className="card-body follow">                              
                            <div className="content">
                                <!--
                                <div className="icon"><a href="https://www.facebook.com/mrwrapper.com.hk"><img src="/uploads/images/icons/icon-fb.svg"></a></div>
                                <div className="icon"><a href="https://www.instagram.com/mrwrapper.hk"><img src="/uploads/images/icons/icon-ig.svg"></a></div>
                            </div>
                        </div>
                    </div>
                </div>
                !--> 
                <div className="new_follow">
                    <h3>追蹤我們</h3>
                    <div className="follow_group">
                        <div className="icon"><a href="https://www.facebook.com/mrwrapper.com.hk"><img src="/web/public/assets/images/followus-fb.svg"/></a></div>
                        <div className="icon"><a href="https://www.instagram.com/mrwrapper.hk"><img src="/web/public/assets/images/followus-ig.svg"/></a></div>
                    </div>
                </div>
            </div> */}
			</div>

			<div className="container-fluid" id="footer_new3">
				<div className="row col-12 col-sm-10 offset-sm-1">
					<div className="common">
						<div className="copyright">
							{/* <div className="logo">
                            <img src={Logo}/>
                        </div> */}
							<div className="content col-12 col-sm-6">
								Copyright @<span>{new Date().getFullYear()}</span> MR. WRAPPER.
								All rights reserved.
							</div>
							<div className="policy col-12 col-sm-6 text-end">
								<span>
									<a href="/web/files/私隱及安全政策.pdf" target="_blank">
										私隱及安全政策
									</a>
								</span>
								<span>
									<a href="/web/files/使用條款協議.pdf" target="_blank">
										使用條款
									</a>
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className="back-to-top">
					<a href="#top">
						<span>TOP</span>
						<i className="fa-solid fa-chevron-up"></i>
					</a>
				</div>
			</div>
		</div>
	);
}

export default memo(Footer);
